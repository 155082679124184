const state = {
  sidebar: {
    opened: true,
  },
  config: {
    palette: {
      primary: "#4ae387",
      danger: "#e34a4a",
      info: "#4ab2e3",
      success: "#db76df",
      warning: "#f7cc36",
      white: "#fff",
      black: "#000",
      fontColor: "#34495e",
      transparent: "transparent",
      lighterGray: "#ddd",
    },
  },
  isLoading: true,
  assetFilters: {
    tag: [],
    type: "",
    simStatus: "",
  },
  searchQuery: "",
};

const mutations = {
  setLoading(state, isLoading) {
    state.isLoading = isLoading;
  },
  setFilters(state, filters) {
    state.assetFilters = filters;
  },
  toggleSidebar(state) {
    state.sidebar.opened = !state.sidebar.opened;
  },
  closeSidebar(state) {
    state.sidebar.opened = false;
  },
  setSearchQuery(state, query) {
    state.searchQuery = query;
  },
};

const actions = {};
const getters = {
  assetFilters: (state) => state.assetFilters,
  sidebar: (state) => state.sidebar,
  searchQuery: (state) => state.searchQuery,
};

export default {
  state,
  mutations,
  actions,
  getters,
};
