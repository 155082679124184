import sixBanner from "./six-ui-components/sixBanner.vue";
import sixNavbar from "./six-ui-components/sixNavbar.vue";
import sixSidebar from "./six-ui-components/sixSidebar.vue";
import sixIcon from "./six-ui-components/sixIcon.vue";
import sixTable from "./six-ui-components/sixTable.vue";
import sixButton from "./six-ui-components/sixButton.vue";
import sixCard from "./six-ui-components/sixCard.vue";
import sixFooter from "./six-ui-components/sixFooter.vue";
import sixSelect from "./six-ui-components/sixSelect.vue";
import sixModal from "./six-ui-components/sixModal.vue";
import sixProgressBar from "./six-ui-components/sixProgressBar.vue";
import sixSwitch from "./six-ui-components/sixSwitch.vue";
import sixChart from "./six-ui-components/sixChart.vue";
import sixLatestActivity from "./six-ui-components/sixLatestActivity.vue";
import sixDoughnutChart from "./six-ui-components/sixDoughnutChart.vue";
import sixChip from "./six-ui-components/sixChip.vue";
import sixDateInput from "./six-ui-components/sixDateInput.vue";
import sixInput from "./six-ui-components/sixInput.vue";
import sixSlider from "./six-ui-components/sixSlider.vue";
import sixMap from "./six-ui-components/sixMap.vue";
import sixToast from "./six-ui-components/sixToast.vue";
import sixAccordion from "./six-ui-components/sixAccordion.vue";
import sixCreditCard from "./six-ui-components/sixCreditCard.vue";
import sixPopover from "./six-ui-components/sixPopover.vue";
import sixCollapsible from "./six-ui-components/sixCollapsible.vue";

const SixUI = {
  install(Vue) {
    Vue.component("six-banner", sixBanner);
    Vue.component("six-navbar", sixNavbar);
    Vue.component("six-sidebar", sixSidebar);
    Vue.component("six-icon", sixIcon);
    Vue.component("six-table", sixTable);
    Vue.component("six-button", sixButton);
    Vue.component("six-card", sixCard);
    Vue.component("six-footer", sixFooter);
    Vue.component("six-select", sixSelect);
    Vue.component("six-modal", sixModal);
    Vue.component("six-progress-bar", sixProgressBar);
    Vue.component("six-switch", sixSwitch);
    Vue.component("six-chart", sixChart);
    Vue.component("six-latest-activity", sixLatestActivity);
    Vue.component("six-doughnut-chart", sixDoughnutChart);
    Vue.component("six-chip", sixChip);
    Vue.component("six-date-input", sixDateInput);
    Vue.component("six-input", sixInput);
    Vue.component("six-slider", sixSlider);
    Vue.component("six-map", sixMap);
    Vue.component("six-toast", sixToast);

    Vue.prototype.sixToast = function (toast) {
      this.$root.$emit("addToast", toast);
    };
    Vue.component("six-accordion", sixAccordion);
    Vue.component("six-credit-card", sixCreditCard);

    Vue.component("six-popover", sixPopover);
    Vue.component("six-collapsible", sixCollapsible);
  },
};

export default SixUI;
