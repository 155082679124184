import axios from "axios";
import store from "../store";
import router from "../router";

const baseURL = process.env.VUE_APP_API_ROOT;

const customAxios = axios.create({
  baseURL: baseURL,
});

customAxios.interceptors.request.use(
  (config) => {
    config.withCredentials = true;

    const backofficeToken = store.getters.backofficeToken;
    if (
      backofficeToken != null &&
      router.currentRoute.meta.requiresBackofficeToken
    ) {
      config.headers["X-Backoffice-Token"] = backofficeToken;
    }

    return config;
  },
  (error) => error
);

customAxios.interceptors.response.use(
  (response) => response,
  ({response}) => {
    if (!response) {
      return {status: 500, statusText: "INTERNAL_SERVER_ERROR"};
    }
    if (
      response.statusText === "UNAUTHORIZED" ||
      response.status === 422 ||
      response.status === 401
    ) {
      store.dispatch("auth_logout");
    }
    if (
      response.status === 403 &&
      router.currentRoute.meta.requiresBackofficeToken
    ) {
      store.dispatch("logout_backoffice");
      router.push({
        name: "BoAccess",
        params: {redirect_url: router.currentRoute.fullPath},
      });
    }
    return response;
  }
);

class SSEHandler {
  constructor(url, configs) {
    this.onmessage = () => {};
    this.onclose = () => {};
    this.onresponse = () => {};

    this.readStream(url, configs);

    this.abortController = new AbortController();
    this.signal = this.abortController.signal;
  }

  async readStream(url, configs) {
    configs = {
      method: "GET",
      credentials: "include",
      params: {},

      json: undefined,

      ...configs,

      signal: this.signal,
    };

    if (configs.json) {
      configs.headers = {
        "Content-Type": "application/json",
        "Accept": "application/json",
        ...configs.headers,
      };

      configs.body = JSON.stringify(configs.json);
    }

    let requestURL = baseURL + url;

    let queryParams = new URLSearchParams(configs.params).toString();

    if (queryParams) {
      requestURL += "?" + queryParams;
    }

    let response = await fetch(requestURL, configs);

    if (!response.ok) {
      let responseData = await response.text();
      let jsonData = {};

      try {
        jsonData = JSON.parse(responseData);
      } catch (error) {
        jsonData = {msg: responseData};
      }

      this.onresponse(response, jsonData);
      this.onclose();
      return;
    }

    let reponseType = response.headers.get("Content-Type");

    if (reponseType.includes("text/event-stream")) {
      this.readSSEResponse(response);
    } else {
      let responseData = await response.text();

      let jsonData = {};
      try {
        jsonData = JSON.parse(responseData);
      } finally {
      }

      this.onresponse(response, jsonData);
    }

    this.onclose();
  }

  async readSSEResponse(response) {
    const reader = response.body.getReader();
    const decoder = new TextDecoder("utf-8");

    while (true) {
      const {value, done} = await reader.read();
      if (done) break;

      const pattern = /(event: (.*))?\n?(data: (.*))?\n?/;

      let sseMessage = decoder.decode(value);

      // Search for the pattern in the input message
      const match = sseMessage.match(pattern);

      if (match) {
        let event = match[2] || ""; // Capture group for event
        let data = match[4] || "";

        if (!event && !data) {
          continue;
        }

        data = JSON.parse(data);

        this.onmessage(event, data);
      }
    }
  }

  close() {
    this.abortController.abort();
  }
}

customAxios.sse = SSEHandler;

export default customAxios;
