<template>
  <div class="latest-activity">
    <div
      v-if="loading"
      class="loading-overlay">
      <div class="spinner"></div>
    </div>
    <div class="activity-title-container">
      <h5 class="medium">Latest Activity</h5>
      <div class="sub-title">
        <div class="icon">
          <six-icon
            name="upArrow"
            class="ml-2">
          </six-icon>
        </div>
        <h6>Latest</h6>
      </div>
    </div>

    <div v-if="data && data.length > 0">
      <!-- If the activity count is more than 4 get the last four activities. -->
      <div
        v-for="(activity, index) in data.slice(0, 4)"
        :key="index"
        class="activity-row"
        :class="{'last-row': isLastRow(index)}">
        <six-icon
          name="emptyCircle"
          class="circle-icon">
        </six-icon>
        <div class="activity-content">
          <div class="uih5">{{ formatLogText(activity) }}</div>
          <div class="uih6">{{ formatDate(activity.timestamp) }}</div>
        </div>
      </div>
    </div>
    <div v-else>
      <div class="uih5">No activities to list.</div>
    </div>
    <div class="button-container">
      <six-button
        v-if="viewAllButton"
        color="primary"
        outlined
        full-width
        :click="onViewAllClick">
        View All Activities
      </six-button>
    </div>
  </div>
</template>

<script>
import {formatLogText} from "../helpers.js";
import SixIcon from "../six-ui-components/sixIcon.vue";
import SixButton from "../six-ui-components/sixButton.vue";

export default {
  name: "SixLatestActivity",

  components: {
    SixIcon,
    SixButton,
  },

  props: {
    data: {
      type: Array,
    },
    viewAllButton: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },

  methods: {
    formatLogText,
    isLastRow(index) {
      return index === this.data.slice(0, 4).length - 1;
    },
    onViewAllClick() {
      this.$emit("view-all-click");
    },
    formatDate(timestamp) {
      if (!timestamp) return "";

      const date = new Date(timestamp * 1000);
      const options = {
        day: "2-digit",
        month: "short",
        hour: "numeric",
        minute: "2-digit",
        hour12: true,
      };

      return date.toLocaleString("en-US", options);
    },
  },
};
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");

.latest-activity {
  position: relative;
  width: 100%;
  display: inline-flex;
  padding: 24px 16px 29px 32px;
  flex-direction: column;
  align-items: flex-start;
  border-radius: 8px;
  background: #fff;
  box-shadow: 0px 10px 13px 0px rgba(17, 38, 146, 0.05);
}

.activity-title-container {
  display: flex;
  flex-direction: column;
  margin-bottom: 24px;
}

.sub-title {
  margin-top: 10px;
  display: flex;
  align-content: center;
  flex-direction: row;
  align-items: flex-start;
  gap: 4px;
}

.icon {
  width: 20px;
  height: 29px;
  display: flex;
  flex-shrink: 0;
  margin-right: 10px;
  place-content: center;
}

.circle-icon {
  margin-right: 1rem;
  margin-top: 4px;
}

.activity-row {
  display: flex;
  align-items: flex-start;
  position: relative;
  margin-bottom: 20px;
}

.activity-content {
  flex: 1;
}

.button-container {
  width: 100%;
  display: flex;
}

.button-container {
  width: 100%;
}

.loading-overlay {
  position: absolute;
  overflow: hidden;
  border-radius: 8px;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  -webkit-backdrop-filter: blur(7px);
  backdrop-filter: blur(7px);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.spinner {
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-top: 4px solid var(--color-primary-solid);
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
  margin: 0 auto;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@media (max-width: 1440px) {
  .latest-activity {
    padding: 21px 31px 20px 24px;
  }
}
</style>
