<template>
  <div id="chart">
    <apexchart
      :type="type"
      :height="height"
      :options="chartOptions"
      :series="data"></apexchart>
  </div>
</template>

<script>
import VueApexCharts from "vue-apexcharts";

/**
      Note: More on apexcharts here:
      https://apexcharts.com/docs/options/
      https://apexcharts.com/vue-chart-demos/area-charts/spline/
  */
export default {
  name: "sixChart",
  components: {
    apexchart: VueApexCharts,
  },

  props: {
    data: {
      /**
       * Schema:
       * [
       * {
       * name: string,
       * data: [],
       * color: string,
       * },
       */
      type: Array,
      required: true,
    },

    height: {
      type: String,
      default: "350",
    },

    xaxis: {
      /**
         * Schema:
         * {
         *  type: 'category',
            categories: [],
         * }
         */
      type: Object,
      default: () => {},
    },

    yaxis: {
      type: Object,
      default: () => {},
    },

    legend: {
      type: Boolean,
      default: false,
    },

    type: {
      type: String,
      default: "area",
    },

    opacityFrom: {
      // This changes the opacity of the gradient
      type: Number,
      default: 0.1,
    },

    tooltip: {
      type: Object,
      default: () => {},
    },

    //chart options to personalize the chart
    options: {
      type: Object,
      default: () => {
        return {
          toolbar: false,
          zoom: true,
          sparkline: false,
          stroke: 2,
        };
      },
    },
  },

  computed: {
    chartOptions() {
      return {
        chart: {
          type: this.type,
          toolbar: {
            show: this.options.toolbar || false,
          },
          zoom: {
            enabled: this.options.zoom || true,
          },
          // sparkline removes all the elements but primary paths. useful for small charts
          sparkline: {
            enabled: this.options.sparkline || false,
          },
        },

        stroke: {
          width: this.options.stroke || 2,
        },

        fill: {
          type: "gradient",
          gradient: {
            type: "vertical",
            shadeIntensity: 1,
            opacityFrom: this.opacityFrom,
            opacityTo: 0,
            stops: [0, 70],
            gradientToColors: [this.data[0]?.color || "#fff"],
          },
        },

        dataLabels: {
          enabled: false,
        },

        legend: {
          show: this.legend,
          position: "top",
          horizontalAlign: "center",
          fontFamily: "Inter",
          fontSize: "16px",
          labels: {
            colors: "var(--color-deactive-text, #8A92A6)",
          },
          itemMargin: {
            horizontal: 24,
          },
        },

        markers: this.options.markers || {
          size: 0,
        },

        xaxis: {
          ...this.xaxis,
          tooltip: {
            enabled: false,
          },
        },

        yaxis: this.yaxis,

        grid: {
          show: false,
        },

        tooltip: {
          ...this.tooltip,
          style: {
            fontFamily: "Inter",
          },
        },

        // Note: title and subtitle are not used for now
        // title: {
        //   text: this.title,
        //   style: {
        //     color: "var(--color-text, #232D42)",
        //     fontSize: "19px",
        //     fontFamily: "Inter",
        //     fontWeight: "500",
        //   },
        //   margin: 0,
        // },

        // subtitle: {
        //   text: this.subtitle,
        //   style: {
        //     color: "var(--color-deactive-text, #8A92A6)",
        //     fontSize: "16px",
        //     fontFamily: "Inter",
        //   },
        //   margin: 0,
        // },
      };
    },
  },
};
</script>
