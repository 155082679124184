<template>
  <img
    class="sflogo"
    :style="`max-width: ${scale} !important;
                  height: auto !important;
                  min-width: 120px;`"
    src="../../static/logo.png" />
</template>

<script>
export default {
  name: "sixfablogo",
  props: {
    scale: {
      type: String,
      default: "auto",
    },
  },
};
</script>

<style></style>
