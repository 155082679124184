<template>
  <div class="accordion">
    <div
      class="accordion-header uih6 medium"
      @click="toggleAccordion">
      <div class="header-text">
        <slot name="header"></slot>
      </div>
      <six-icon
        name="accordionArrow"
        class=""
        :style="{
          transform: isOpen ? 'rotate(180deg)' : 'rotate(0)',
        }"></six-icon>
    </div>
    <div
      ref="content"
      class="accordion-content uih6 medium grey"
      :style="{maxHeight: contentMaxHeight + 'px'}">
      <div class="content-border"></div>
      <div class="content-text">
        <slot name="content"></slot>
      </div>
    </div>
  </div>
</template>

<script>
import SixIcon from "../six-ui-components/sixIcon.vue";

export default {
  name: "SixAccordion",

  components: {SixIcon},

  data() {
    return {
      isOpen: false,
      contentMaxHeight: 0,
    };
  },

  methods: {
    toggleAccordion() {
      this.isOpen = !this.isOpen;
      this.updateContentHeight();
    },
    updateContentHeight() {
      this.$nextTick(() => {
        const content = this.$refs.content;
        if (content) {
          this.contentMaxHeight = this.isOpen ? content.scrollHeight : 0;
        }
      });
    },
  },
};
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");

.accordion {
  font-family: Inter;
  width: 100%;
  padding: 24px 32px 0px 32px;
  border-radius: 8px;
  background: #fff;
  box-shadow: 0px 10px 13px 0px rgba(17, 38, 146, 0.05);
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.accordion-header {
  transition: 0.4s;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 5px;
  padding-top: 5px;
  cursor: pointer;
  height: 50px;
}

.header-text {
  width: 75%;
}

.content-border {
  margin-bottom: 24px;
  height: 1px;
  background-color: #e7e7e7;
  margin-left: -32px;
  margin-right: -32px;
}

.accordion-content {
  overflow: hidden;
  transition: max-height 0.4s ease-out;
  justify-content: center;
  align-items: center;
  text-align: justify;
  font-feature-settings: "clig" off, "liga" off;
}

.content-text {
  padding-bottom: 24px;
}

@media (max-width: 1440px) {
  .accordion {
    width: 100%;
    padding: 16px 28px 0px 28px;
  }

  .accordion-header {
    padding-bottom: 0;
  }

  .content-border {
    margin-bottom: 16px;
  }
}
</style>
