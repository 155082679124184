<template>
  <div
    id="app"
    class="app">
    <six-header> </six-header>
    <router-view />
    <six-toast-container></six-toast-container>
  </div>
</template>

<script>
import SixHeader from "../six-ui-components/sixHeader.vue";
import SixToastContainer from "../six-ui-components/sixToastContainer.vue";

export default {
  name: "app",
  components: {SixHeader, SixToastContainer},
};
</script>

<style lang="scss">
@import "../sass/main.scss";

body {
  height: 100%;

  #app {
    height: 100%;
  }
}
</style>
˚
