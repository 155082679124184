<template>
  <div class="footer">
    <div class="links">
      <a href="https://sixfab.com/privacy/">Privacy Policy</a>
      <a href="https://sixfab.com/terms/">Terms of Use</a>
    </div>
    <div class="copyright">
      Copyright © {{ currentYear }} Sixfab. All rights reserved.
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      currentYear: new Date().getFullYear(),
    };
  },
};
</script>

<style scoped>
.footer {
  display: flex;
  flex-direction: row;
  height: 51px;
  padding: 0px 24px;
  align-items: center;
  background: #fff;
  justify-content: space-between;
}

.links {
  display: flex;
  flex-direction: row;
  gap: 24px;
}
.links a,
.links a:visited,
.links a:hover,
.links a:active {
  color: #000;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;

  text-decoration: none;
  cursor: pointer;
}

.copyright {
  color: #000;
  font-family: Inter;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
</style>
