import store from "../store/index";

export default (groupOrGroups) => {
  let customer = store.getters.customer;

  if (!customer) {
    return false;
  }

  let customerGroup = customer.groups || [];

  if (typeof groupOrGroups === "string") {
    return customerGroup.includes(groupOrGroups);
  }

  return groupOrGroups.every((group) => customerGroup.includes(group));
};
