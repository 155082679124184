import {createAuth0Client} from "@auth0/auth0-spa-js";

async function initAuth0Client() {
  var auth = createAuth0Client({
    domain: process.env.VUE_APP_AUTH_DOMAIN,
    clientId: process.env.VUE_APP_AUTH_CLIENT_ID,
    authorizationParams: {
      redirect_uri: window.location.origin,
    },
  });

  return auth;
}

export default initAuth0Client;
