import { render, staticRenderFns } from "./sixBanner.vue?vue&type=template&id=28384b02&scoped=true&"
import script from "./sixBanner.vue?vue&type=script&lang=js&"
export * from "./sixBanner.vue?vue&type=script&lang=js&"
import style0 from "./sixBanner.vue?vue&type=style&index=0&id=28384b02&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "28384b02",
  null
  
)

export default component.exports