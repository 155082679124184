// Polyfills
import "es6-promise/auto";
import "babel-polyfill";
// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import Vue from "vue";
import App from "./App";
import store from "../store/index";
import router from "../router/index";
import "../i18n/index";
import VeeValidate from "vee-validate";

import _axios from "./Api";
import VueAxios from "vue-axios";
import initAuth0Client from "../auth";

import "../metrics";
import "../colors.css";
import "../typography.css";

import SixUI from "../SixUIPlugin";

import permissionsMixin from "../mixins/permissionsMixin";

Vue.use(VeeValidate, {fieldsBagName: "formFields"});
import SixfabLogo from "../components/Logo.vue";
import LoadingSpinner from "../components/LoadingSpinner.vue";

Vue.use(VueAxios, _axios);
Vue.prototype.$auth0 = await initAuth0Client();

import {loadStripe} from "@stripe/stripe-js";

loadStripe(process.env.VUE_APP_STRIPE_KEY).then((resp) => {
  Vue.prototype.$stripe = resp;
});

Vue.component("loading-spinner", LoadingSpinner);
Vue.component("logo", SixfabLogo);

// SixUI
Vue.use(SixUI);

Vue.mixin(permissionsMixin);

router.beforeEach((to, from, next) => {
  store.commit("setLoading", true);
  next();
});

router.afterEach((to, from) => {
  store.commit("setLoading", false);
});

/* eslint-disable no-new */
new Vue({
  el: "#app",
  router,
  store,
  render: (h) => h(App),
});
